import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';

import CategoryProjectGridItem from './CategoryProjectGridItem';
import StyledButton from './StyledButton';

const Wrapper = styled.div`
  width: 100%;
  padding: 3.2rem 5%;
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    padding: 3.2rem 7.77rem;
  }
  h1 {
    ${props => props.theme.font.h3};
    margin-bottom: 3.05rem;
  }
  .project {
    color: ${props => props.theme.colors.white};
    min-height: calc(100vh - 8rem);
    h3,
    h4 {
      margin-bottom: 0.2rem;
      color: ${props => props.theme.colors.white};
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding:3.7rem ${props => props.theme.basicModulePadding.l.left};
    h1{
      word-wrap:normal;
      margin-bottom:0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding:2.7rem ${props => props.theme.basicModulePadding.s.left};
    h1{
      letter-spacing:0.1rem;
      ${props => props.theme.font.subtitle.l};
    }
  }
`;

const ProjectItems = styled(Flex)`
  flex-wrap: wrap;
  /* align-items:center; */
  justify-content: space-between;
  button {
    background-color: ${props => props.theme.colors.black};
    a {
      &,
      &:visited,
      &:link {
        color: ${props => props.theme.colors.white};
      }
      &:hover {
        color: ${props => props.theme.colors.greyLight};
      }
    }
  }
`;
const WrapperViewMore = styled(Flex)`
  justify-content:flex-end;
`;
const CategoryProjectGrid = ({ slugifiedTag, data, items }) => {
  return (
    <Wrapper>
      { ReactHtmlParser(data.section_title.html) }
      <ProjectItems flexDirection='row'>
        { items.map(item => {

          if (item.projects) {
            return <CategoryProjectGridItem
              key={item.projects.id}
              slugifiedTag={slugifiedTag}
              uid={item.projects.uid}
              tags={item.projects.tags}
              data={item.projects.document[0].data} />
          }
          return null
        })}
      </ProjectItems>
      <WrapperViewMore>
      <StyledButton button_color={data.box_colour} link_url={data.link_path} link_text={data.link_title} />
      </WrapperViewMore>
    </Wrapper>
  )
}

export default CategoryProjectGrid;

CategoryProjectGrid.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  tag: PropTypes.string,
};
