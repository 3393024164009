import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';

import CategoryProjectGridItem from './CategoryProjectGridItem';
import StyledButton from './StyledButton';

/*
 TODO : load the projects from the slice first
  - this could be achieved by sorted the projects based on the data being fed in
*/

const Wrapper = styled.div`
  width: 100%;
  padding: 4.44rem 7.77rem;
  margin-top:-7rem;
  h1 {
    ${props => props.theme.font.h3};
    margin-bottom:0;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding:3.7rem ${props => props.theme.basicModulePadding.l.left};
    h1{
      word-wrap:normal;
      margin-bottom:0;
      display:none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding:3.7rem ${props => props.theme.basicModulePadding.s.left};
  }
  .project {
    color: ${props => props.theme.colors.white};
    min-height: calc(100vh - 8rem);
    h3,
    h4 {
      margin-bottom: 0.2rem;
      color: ${props => props.theme.colors.white};
    }
  }
  .hidden {
    display: none;
  }
`;

const FilterButton = styled.button`
  border: 2px solid ${props => props.theme.colors.yellow};
  background: white;
  margin:0 0.5rem;
  padding:0.5rem 0.7rem;
  cursor:pointer;
  ${props => props.theme.font.tags};
  &:last-child{
    margin-right:0;
  }
  &.active {
    background: ${props => props.theme.colors.yellow};
    color: white;
  }

  @media (max-width: ${props => props.theme.breakpoint.s}) {
    margin:1.11rem 0;
    ${props => props.theme.font.styledButton.s};
    margin-left:0.5rem;
  }
`;

const GridTitleBar = styled(Flex)`
  align-items:center;
  justify-content:space-between;
  margin-bottom:2.9rem;

  z-index:0;
  position:relative;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    margin-bottom:0;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    flex-direction:column;
  }
`;
const WrapperFilter = styled(Flex)`
  text-align: right;
  justify-content: flex-end;
  width:50%;

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    width:100%;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {

  }
`;

const ViewMoreButton = styled.button`
  border: none;
  background: ${props => props.theme.colors.yellow};
  width: 100%;
  color: white;
  &.hidden {
    display: none;
  }
`;

const ProjectItems = styled(Flex)`
  flex-wrap: wrap;
  /* align-items:center; */
  justify-content: space-between;
  button {
    background-color: ${props => props.theme.colors.black};
    a {
      &,
      &:visited,
      &:link {
        color: ${props => props.theme.colors.white};
      }
      &:hover {
        color: ${props => props.theme.colors.greyLight};
      }
    }
  }
`;

const WrapperViewMore = styled(Flex)`
  justify-content:flex-end;
`;
const CategoryProjectGridInfinite = ({ slugifiedTag, tag, data, items, projects }) => {
  const filterContent = tag => {

    if (tag.replace(' ', '-') === allowedTag) tag = 'work';
    allowedTag = tag.replace(' ', '-');

    // could probably query selector all right here
    const divsToHide = document.getElementsByClassName('category-project-item');
    const elems = document.querySelectorAll('.filters');
    activeFilter = tag;



    [].forEach.call(elems, el => {
      // el.className = el.className.replace(/\bactive\b/, '');
      if (el.classList.contains('active')) el.classList.remove('active');
    });

    if (tag !== 'work') {
      document.getElementById(`filter-${allowedTag}`).classList.add('active');


      for (let i = 0; i < divsToHide.length; i++) {
        const item = divsToHide[i];

        if (item.classList.contains(allowedTag)) {
          item.style.visibility = 'visible';
          item.style.display = 'block';
        } else {
          item.style.visibility = 'hidden';
          item.style.display = 'none';
        }
      }
      document.getElementById('view-more-button').classList.add('hidden');
    }else {
      document.getElementById('view-more-button').classList.remove('hidden');
      resetProjectList();
    }
  };
  const showMoreProjects = () => {
    pageNum++;
    if (pageNum >= numPages) {
      document.getElementById('view-more-button').classList.add('hidden');
    }
    displayPage();
  };
  const displayPage = () => {

      const showProject = pageNum * numPerPage;
      const elems = document.querySelectorAll('.category-project-item');

      [].forEach.call(elems, el => {
        // need to check to see if the project should be visible
        // TODO : this could be done betterly
        for (let i = 0; i <= showProject-1; i++) {
          if (el.classList.contains(`project-${i}`) && el.classList.contains('hidden')) el.classList.remove('hidden');
        }
      });
  }
  const resetProjectList = () => {
    pageNum = 1;
    const elems = document.querySelectorAll('.category-project-item');
    [].forEach.call(elems, el => {
      el.classList.add('hidden');
      el.style.visibility = '';
      el.style.display = '';
    });
    displayPage();
  }
  const numPerPage = 8;
  const numPages = Math.ceil(projects.length / numPerPage);

  let pageNum = 1;
  let allowedTag = 'work';
  let filters = null;
  let tags = [];
  let activeFilter = "";
  projects.map(edge => {
    tags = tags.concat(edge.node.tags.filter(item => tags.indexOf(item) < 0 && item !== 'work'));
  });
  //sort for alphabetical order
  filters = tags.sort().map((tag, idx) => (
    <FilterButton
      key={idx}
      className="filters"
      id={`filter-${tag.replace(' ', '-')}`}
      onClick={() => filterContent(tag)}
    >
      {tag}
    </FilterButton>
  ));

  return (
    <Wrapper>
      <GridTitleBar>
      {ReactHtmlParser(data.section_title.html)}
      <WrapperFilter>
        {filters}
      </WrapperFilter>
      </GridTitleBar>
      <ProjectItems flexDirection="row">
        {projects.map((edge, idx) => {
          const project = edge.node;

          return (
            <CategoryProjectGridItem
              key={project.id}
              gridIndex={idx}
              referenceTag={tag}
              uid={project.uid}
              tags={project.tags}
              data={project.data}
              slugifiedTag={slugifiedTag}
              className={idx >= numPerPage ? `hidden project-${idx}` : `project-${idx}`}
            />
          );
        })}
      </ProjectItems>

      <WrapperViewMore id="view-more-button" onClick={() => showMoreProjects()}
      className={(numPerPage > projects.length) ? 'hidden' : ''} >
      {/*
        <ViewMoreButton
          id="view-more-button"
          className={(numPerPage > projects.length) ? 'hidden' : ''}
          onClick={() => showMoreProjects()}
        >
          {data.link_title}
        </ViewMoreButton>
         */}
         <StyledButton
         link_text={data.link_title} button_color="Yellow" />
      </WrapperViewMore>

    </Wrapper>
  );
};

export default CategoryProjectGridInfinite;

CategoryProjectGridInfinite.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  tag: PropTypes.string,
};
