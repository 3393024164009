import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';
import StyledButton from './StyledButton';

import plusSymbol from "../images/plusSymbol.svg"
import overlayMeshWhite from "../images/overlayMeshWhite.svg"

const WrapperOverlay = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:${props=>props.theme.colors.red}50;

  &.overlay_production{
    background-color:${props=>props.theme.colors.lightPink}50;
  }
  &.overlay_augmented-reality{
    background-color:${props=>props.theme.colors.red}50;
  }
  &.overlay_digital{
    background-color:${props=>props.theme.colors.yellow}50;
  }
  &.overlay_work{
    &.overlay_variant_1{
      background-color:${props=>props.theme.colors.lightPink}50;
    }
  }
  &.overlay_work:nth-child(3){
    &.overlay_variant_2{
      background-color:${props=>props.theme.colors.yellow}50;
    }
  }
  overflow:hidden;
  opacity:0;
  transition:opacity 0.1s ease-out;

  .plusSymbol, .overlayMeshWhite{
    position:absolute;
  }
  .plusSymbol{
    width:100%;
    height:100%;
    top:0;
    left:0;
    &:before, &:after{
      transition: all 0.4s ease;
      content:'';
      background-color:#FFF;
      position:absolute;
      transform:translate(-50%,-50%);
      opacity:0;
    }
    &:before{
      width:0.5vw;
      height:35vw;
      left: 50%;
      top:-50%;
    }
    &:after{
      width:35vw;
      height:0.5vw;
      top: 50%;
      left:150%;
    }
  }
  .overlayMeshWhite{
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit:cover;
  }
  .wrapperStyledButton{
    right:1.11rem;
    bottom: 1.11rem;
    position:absolute;
    pointer-events:none;
  }
`;
const HoverOverlay = ( {isProject, referenceTag, variant}) => {
  let colorVariant = 0;
  if (variant){
    colorVariant = variant
  }
  return(
    <WrapperOverlay className={"hoverOverlay overlay_" + referenceTag + " overlay_variant_"+colorVariant}>
      <div className="plusSymbol"></div>
      {isProject &&
        <img className="overlayMeshWhite" src={overlayMeshWhite} />
      }
      {isProject &&
        <StyledButton className="buttonDetails" flatStyle={true} link_text="View Details"></StyledButton>
      }
    </WrapperOverlay>
  )
}

export default HoverOverlay;

HoverOverlay.propTypes = {
  isProject: PropTypes.bool,
  referenceTag: PropTypes.string,
  variant: PropTypes.number
}
