import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';

import Layout from '../components/Layout';
import CategoryProjectGrid from '../components/CategoryProjectGrid';
import CategoryProjectGridInfinite from '../components/CategoryProjectGridInfinite';
import ContentSection from '../components/ContentSection';
import HeroFeatureSection from '../components/HeroFeatureSection';

const Wrapper = styled(Flex)`
  /* max-width: ${props => props.theme.maxWidth}; */
  flex-direction:column;
  .contentSection{
    min-height:90vh;
  }
`;

const TagCollection = ({ pageContext, data: { category, projects } }) => {
  const hasCategories = category.edges.length > 0 && category.edges[0].node.data.body != null;
  return (
    <Layout>
      <Wrapper className="wrapperTagCollection">
        {hasCategories &&
          category.edges[0].node.data.body
            .filter(
              slice => slice.items || slice.primary // filter out slice if it lacks content
            )
            .map((slice, idx) => {
              switch (slice.__typename) {
              case 'PrismicCategoryBodyFeatureSection':
                return <HeroFeatureSection key={slice.id} data={slice.primary} isTagCollection />
                case 'PrismicCategoryBodyProjectGrid':
                  if (pageContext.tag === 'work') {
                    return (
                      <CategoryProjectGridInfinite
                        key={slice.id}
                        tag={pageContext.tag}
                        slugifiedTag={pageContext.slugifiedTag}
                        data={slice.primary}
                        items={slice.items}
                        projects={projects.edges}
                      />
                    );
                  }
                  return (
                    <CategoryProjectGrid
                      key={slice.id}
                      slugifiedTag={pageContext.slugifiedTag}
                      data={slice.primary}
                      items={slice.items}
                    />
                  );
              case "PrismicCategoryBodyContentSection":
                return <ContentSection key={slice.id} isCategory tag={pageContext.tag} data={slice} />
              default:
                return <div key={idx}>unknown slice: {slice.__typename}</div>;
              }
            })}
      </Wrapper>
    </Layout>
  );
};

export default TagCollection;

TagCollection.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    template: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    category: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
query TagCollectionQuery ($tag: [String], $slugifiedTag: [String]){
    category: allPrismicCategory (filter:{ uid: { in: $slugifiedTag, nin:"unpublished"}}) {
      edges {
        node {
          id
          slugs
          data {
            page_title {
              html
              text
            }
            page_description {
              html
              text
            }
            body {
              __typename
              ... on PrismicCategoryBodyFeatureSection {
                id
                primary {
                  feature_subtitle {
                    html
                    text
                  }
                  client_title {
                    html
                    text
                  }
                  project_title {
                    html
                    text
                  }
                  project_link_url
                  button_colour
                  button_link_label
                  button_link
                  feature_image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                    alt
                    url
                  }
                }
              }
              ... on PrismicCategoryBodyContentSection{
                id
                primary {
                  fragment_id
                  heading {
                    html
                    text
                  }
                  sub_heading {
                    html
                    text
                  }
                  section_copy {
                    html
                    text
                  }
                  background_image {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                    alt
                  }
                  box_colour
                  media_alignment
                }
                items {
                  gallery_image {
                    alt
                    copyright
                    url
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1920, quality: 90) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicCategoryBodyProjectGrid {
                id
                primary {
                  link_title
                  link_path
                  box_colour
                  section_title {
                    html
                    text
                  }
                }
                items {
                  projects {
                    id
                    tags
                    uid
                    document {
                      id
                      data {
                        client_name {
                          html
                          text
                        }
                        project_name
                        reference_image {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProject(
      filter: { tags: { in: $tag, nin: "unpublished" } }
      sort: { fields: [last_publication_date], order: DESC }
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            client_name {
              html
              text
            }
            project_name
            reference_image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
