import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image'

import StyledButton from './StyledButton';
import HoverOverlay from './HoverOverlay';

const HeroFeature = styled.div`
  width: 100%;
  height:97vh;
  @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
    ${props => props.theme.featureHeight.l};
  }
  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    height:Calc(51vh - 2.88rem);
  }
  .project {
    color: ${props => props.theme.colors.white};
    min-height: 100%;

    @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
      min-height:${props => props.theme.featureHeight.l.height};
    }
    @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
      min-height:Calc(51vh - 2.88rem);
    }
    h3, h4, h5 {
      margin-bottom: 0;
      color: ${props => props.theme.colors.white};
    }

    h3{
      ${props => props.theme.font.h1}
      line-height:1em;
      @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
        ${props => props.theme.font.h1.l};
      }

      @media (max-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.h1.s};
      }
    }
    h4{
      ${props => props.theme.font.subtitle}
      @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
        ${props => props.theme.font.subtitle.l};
      }
      @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.subtitle.s};
      }
    }
    h5{
      @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
        margin-bottom:0.27rem;
      }
    }
  }
  &.tagCollection{

    height: 83vh;

    @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
      ${props => props.theme.featureHeight.l};
    }
    @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
      height:Calc(51vh - 2.88rem);
    }
    .projectText{
      h5{
        background-color:${props => props.featureSubtitleBG};
        ${props => props.theme.font.h3}
        letter-spacing:0;
        font-family: "korolev-condensed", "korolev", sans;
        padding: 0.44rem 1.25rem;
        margin-bottom:0.5rem;
        text-align:center;
        @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
          ${props => props.theme.font.h3.l}
          padding:0.1rem 0.35rem;
          line-height:1.66rem;
          display:inline-block;
        }

        @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
          ${props => props.theme.font.h3.s}
          padding:0.1rem 0.35rem;
          line-height:1rem;
          margin-bottom:0;
        }
      }
    }
  }
`;

const Heading = styled(Flex)`
  height: 8rem;
  background-color: ${props => props.bgcolor ? props.bgcolor : props.theme.colors.greyDark};
  align-items: center;
  h2 {
    color: ${props => props.bgcolor && props.bgcolor === '#282828' ? props.theme.colors.white : props.theme.colors.greyDark};
    margin-bottom:0;
  }
  & + .project{
    min-height: calc(100vh - 8rem);
  }
`;

const FeatureProject = styled(Flex)`
  align-items: flex-end;
  justify-content: space-between;
  height:Calc(83vh - 4.0rem);
  @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
    height:Calc(${props => props.theme.featureHeight.l.height} - 2.88rem);
  }
  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    height:Calc(51vh - 2.88rem);
  }
`;

const ProjectText = styled.div`
  left:${props => props.theme.basicModulePadding.left};
  position:absolute;
  bottom: 4.5rem;
  z-index: 5;
  pointer-events:none;
  @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
    left:${props => props.theme.basicModulePadding.l.left};
    bottom: 2.88rem;
  }

  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    left:${props => props.theme.basicModulePadding.s.left};
    bottom:${props => props.theme.basicModulePadding.s.bottom};

    max-width:45%;
  }
`;
const ProjectLink = styled.div`
  align-self: flex-end;
  padding-right: 0;
  position:absolute;
  right:${props => props.theme.basicModulePadding.right};
  bottom: 4.0rem;
  @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
    right:${props => props.theme.basicModulePadding.l.right};
    bottom: 2.88rem;
  }

  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    right:${props => props.theme.basicModulePadding.s.right};
    bottom:${props => props.theme.basicModulePadding.s.bottom};
    .wrapperStyledButton
      button{
        .styledItem{
          font-size:${props => props.theme.font.h3.s};
        }
      }
  }
`;

const HeroFeatureSection = ({ isTagCollection, data }) => {
  let referenceTag = "work";
  if (data.project_link_url.indexOf("augmented-reality") != -1){
    referenceTag = "augmented-reality";
  }else if (data.project_link_url.indexOf("production") != -1){
    referenceTag = "production";
  }else if (data.project_link_url.indexOf("digital") != -1){
    referenceTag = "digital";
  }
  let classNameValues = "";
  if (isTagCollection){
    classNameValues = "tagCollection";
  }
  return (
    <HeroFeature className={classNameValues} featureSubtitleBG={data.button_colour}>
      <BackgroundImage fluid={data.feature_image.localFile.childImageSharp.fluid} className='project'>
        <Link to={data.project_link_url}>
          <FeatureProject >
            <ProjectText className="projectText">
              { ReactHtmlParser(data.feature_subtitle.html)}
              { ReactHtmlParser(data.client_title.html) }
              { ReactHtmlParser(data.project_title.html) }
            </ProjectText>
          </FeatureProject>
          <HoverOverlay referenceTag={referenceTag} className={"hoverOverlay"}></HoverOverlay>
        </Link>
        <ProjectLink>
          <StyledButton button_color={data.button_colour} link_url={data.button_link} link_text={data.button_link_label} ></StyledButton>
        </ProjectLink>
      </BackgroundImage>
    </HeroFeature>
  )
}

export default HeroFeatureSection;

HeroFeatureSection.propTypes = {
  data: PropTypes.object.isRequired
};
