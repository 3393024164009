import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';
import StyledButton from './StyledButton';
import HoverOverlay from './HoverOverlay';

const Item = styled.div`
  width: calc(100% / 2 - 1.3rem);
  text-align: left;
  margin-bottom: 3.66rem;
  flex-direction: column;
  &.hidden {
    display: none;
  }
  .bgImage {
    width: 100%;
    display: flex;
    flex-grow: 1;
    padding: 1rem;
    margin-bottom: 1.66rem;
    height: 23.3vw;

  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    /* margin-top: 3rem !important; */
    margin-top: 1.5rem !important;
    margin-bottom:1.5rem;
    .bgImage{
      height: calc(46.33vw);

      margin-bottom:0.62rem;
    }
  }
`;

const ProjectTitle = styled.div`
  color: ${props => props.theme.colors.black};
  ${props => props.theme.font.projectTitle};
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    ${props => props.theme.font.projectTitle.l};
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    ${props => props.theme.font.projectTitle.s};
  }
  margin-top: auto;
  transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: translateY(0rem);
`;

const ItemSubtitle = styled.div`
  color: ${props => props.theme.colors.greyMedium};
`;

const CategoryProjectGridItem = ({ slugifiedTag, uid, data, tags, className, gridIndex }) => {
  const visibleClass = className || '';
  return (
    <Item className={`${tags.map(tag => tag.replace(' ', '-')).join(' ')} category-project-item ${visibleClass}`}>
      <Link to={`/projects/${slugifiedTag}/${uid}`}>
        {data.reference_image.localFile && (
          <BackgroundImage fluid={data.reference_image.localFile.childImageSharp.fluid} className="bgImage">
            <HoverOverlay isProject referenceTag={slugifiedTag} variant={gridIndex%3} />
          </BackgroundImage>
        )}
        <ProjectTitle>
          {`${data.client_name.text} `}
          <span className="colorRed">\</span>
          {` ${data.project_name}`}
        </ProjectTitle>
      </Link>
    </Item>
  );
};
/*
{tags.map(tag => {
  return <Link key={tag} to={`/projects/${tag}`}>{tag}</Link>
})}
*/
export default CategoryProjectGridItem;

CategoryProjectGridItem.propTypes = {
  data: PropTypes.shape({
    reference_image: PropTypes.shape({
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object.isRequired,
        }),
      }),
      client_name: PropTypes.objectOf({
        text: PropTypes.string.isRequired,
      }),
      project_name: PropTypes.string,
    }),
  }).isRequired,
  tags: PropTypes.array.isRequired,
  slugifiedTag:PropTypes.string.isRequired,
  uid:PropTypes.string.isRequired,
  className: PropTypes.string,
  gridIndex: PropTypes.number,
};
